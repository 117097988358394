<template>
  <div id="page-add-room">
    <div class="vx-row">
      <!-- Create room -->
      <div class="vx-col w-full mb-base">
        <div class="vx-card p-6">
          <h3 class="text-xl font-medium mb-5">Create New Room</h3>

          <!-- Create new room form -->
          <div class="vx-row">
            <div class="vx-col w-full">
              <div class="vx-row mb-3">
                <!-- Room type selection -->
                <div class="vx-col w-full md:w-6/12 mb-3">
                  <span class="mb-2 block text-sm">Room Type (*)</span>
                  <v-select
                    v-validate="'required'"
                    data-vv-validate-on="blur"
                    name="roomType"
                    label="name"
                    data-vv-as="Room Type"
                    :options="roomTypes"
                    :reduce="(v) => v.id"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="payload.roomTypeId"
                  />
                  <span class="block text-sm mt-2 text-danger">{{
                      errors.first("roomType")
                    }}</span>
                </div>

                <!-- Room name selection -->
                <div class="vx-col w-full md:w-6/12 mb-3">
                  <span class="mb-2 block text-sm">Room Name (*)</span>
                  <v-select
                    v-validate="'required'"
                    data-vv-validate-on="blur"
                    name="roomName"
                    label="name"
                    data-vv-as="Room Name"
                    :options="roomNames"
                    :reduce="(v) => v.id"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="payload.roomNameId"
                  />
                  <span class="block text-sm mt-2 text-danger">{{
                      errors.first("roomName")
                    }}</span>
                </div>

                <!-- Display name input -->
                <div class="vx-col w-full md:w-6/12 mb-3">
                  <vs-input
                    v-validate="'required'"
                    data-vv-validate-on="blur"
                    name="displayName"
                    data-vv-as="Display Name"
                    class="w-full"
                    label="Display Name (*)"
                    v-model="payload.name"
                  />
                  <span class="block text-sm mt-2 text-danger">{{
                    errors.first("displayName")
                  }}</span>
                </div>

                <!-- Smoking policy selection -->
                <div class="vx-col w-full md:w-6/12 mb-3">
                  <span class="mb-2 block text-sm">Smoking Policy (*)</span>
                  <v-select
                    name="isSmokingAllowed"
                    :options="smokingPolicyOptions"
                    label="display"
                    :reduce="(val) => val.value"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="payload.isSmokingAllowed"
                  />
                  <span class="block text-sm mt-2 text-danger">{{
                    errors.first("isSmokingAllowed")
                  }}</span>
                </div>
              </div>

              <!-- Property Description -->
              <div class="vx-row">
                <div class="vx-col w-full">
                  <span class="mb-2 block text-sm">Description</span>
                  <quill-editor
                    v-model="payload.description"
                    :options="editorOption"
                    @ready="onEditorReady($event)"
                    ref="myQuillEditor"
                  ></quill-editor>
                  <span class="block text-sm mt-2 text-danger">{{
                    errors.first("description")
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Bed Option selection -->
      <div class="vx-col w-full mb-base">
        <div class="vx-card p-6">
          <h3 class="text-xl font-medium mb-5">Bed Options</h3>

          <!-- Bed form -->
          <div class="vx-row select-none">
            <div
              class="vx-col w-full"
              v-for="(bedGroup, i) in payload.beds"
              :key="i"
            >
              <div class="vx-row flex items-end">
                <!-- Bed selection -->
                <div class="vx-col w-full md:w-5/12">
                  <span class="mb-2 block text-sm">Bed (*)</span>
                  <v-select
                    label="name"
                    :options="bedTypes"
                    :reduce="(bed) => bed.id"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="payload.beds[i]"
                  />

                  <span class="block text-sm mt-2 text-danger">{{
                    errors.first("beds")
                  }}</span>
                </div>

                <div class="vx-col w-full md:w-2/12 mb-2">
                  <vs-button
                    :disabled="payload.beds.length < 2"
                    type="border"
                    color="danger"
                    icon="delete"
                    @click="payload.beds.splice(i, 1)"
                  >
                    Delete
                  </vs-button>
                </div>
              </div>
            </div>

            <div class="vx-col w-full mt-3">
              <vs-button icon="add" @click="addBed">Add New</vs-button>
            </div>
          </div>
        </div>
      </div>

      <!-- Occupancy -->
      <div class="vx-col w-full">
        <div class="vx-card p-6">
          <h3 class="text-xl font-medium mb-3">Occupancy</h3>
          <p class="text-base">
            What is the total number of guests (adults and children) that can
            stay here?
          </p>

          <!-- Occupancy form -->
          <div class="vx-row mb-4 mt-5">
            <div class="vx-col w-full lg:w-6/12">
              <vs-input
                disabled="true"
                type="number"
                min="0"
                class="w-full"
                label="Maximum Guest"
                v-model="payload.maxOccupancy"
              />
            </div>
          </div>

          <div class="vx-row mb-4">
            <div class="vx-col w-full lg:w-6/12">
              <vs-input
                type="number"
                min="0"
                class="w-full"
                label="Maximum Adults (*)"
                v-model="payload.maxAdults"
              />
            </div>
          </div>

          <div class="vx-row">
            <div class="vx-col w-full lg:w-6/12">
              <vs-input
                type="number"
                min="0"
                class="w-full"
                label="Maximum Children (*)"
                v-model="payload.maxChildren"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- Submit button -->
      <div class="vx-row p-4 mt-3">
        <div class="vx-col w-full">
          <el-button size="medium"
                     type="primary"
                     class="block float-left"
                     :loading="isSaving"
                     :disabled="isSaving"
                     @click="storeData">
            Submit
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";
import vSelect from "vue-select";

// Quill Editor Library
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import Quill from "quill";
import { quillEditor } from "vue-quill-editor";

export default {
  name: "AddPropertyRoom",

  metaInfo: {
    title: "Add Room",
    titleTemplate: "%s - Vancy Net",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },

  components: {
    "v-select": vSelect,
    quillEditor,
  },

  watch: {
    payload: {
      handler(payload) {
        // max guest handler
        this.payload.maxOccupancy =
          parseInt(payload.maxAdults) + parseInt(payload.maxChildren);
      },
      deep: true,
    },
  },

  computed: {
    editor() {
      return this.$refs.myQuillEditor.quill;
    },
  },

  data() {
    return {
      isSaving: false,
      payload: {
        roomTypeId: null,
        roomNameId: null,
        name: "",
        description: "",
        isSmokingAllowed: false,
        maxOccupancy: 0,
        maxAdults: 0,
        maxChildren: 0,
        beds: [],
      },

      // Options
      roomTypes: [],
      roomNames: [],
      bedTypes: [],
      smokingPolicyOptions: [
        {
          display: "Yes",
          value: true,
        },
        {
          display: "No",
          value: false,
        },
      ],
      editorOption: {
        // some quill options
        modules: {
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"], // toggled buttons
              ["blockquote", "code-block"],

              [{ header: 1 }, { header: 2 }], // custom button values
              [{ list: "ordered" }, { list: "bullet" }],
              [{ script: "sub" }, { script: "super" }], // superscript/subscript
              [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
              [{ direction: "rtl" }], // text direction

              [{ size: ["small", false, "large", "huge"] }], // custom dropdown
              [{ header: [1, 2, 3, 4, 5, 6, false] }],

              [{ color: [] }, { background: [] }], // dropdown with defaults from theme
              [{ font: [] }],
              [{ align: [] }],

              ["clean"], // remove formatting button
            ],
          },
        },
      },
    };
  },

  methods: {
    onEditorReady(quill) {
      console.log("editor ready!", quill);
    },

    // add bed input
    addBed() {
      this.payload.beds.push(0);
    },

    // get all sub category products
    async getAllData() {
      try {
        const res1 = await axios.get(`room-types`);
        if (res1.status === 200) {
          this.roomTypes = res1.data.data.map(v => ({
            id: v.id,
            name: v.name,
          }));
        }

        const res2 = await axios.get(`room-names`);
        if (res2.status === 200) {
          this.roomNames = res2.data.data.map(v => ({
            id: v.id,
            name: v.name,
          }));
        }

        const res3 = await axios.get(`bed-types`);
        if (res3.status === 200) {
          this.bedTypes = res3.data.data.map(v => ({
            id: v.id,
            name: v.name,
          }));
        }
      } catch (err) {
        this.$catchErrorResponse(err);
      }
    },

    // store data
    async storeData() {
      if (!this.$isValidDescription(this.payload.description)) return;

      const hotelId = this.$store.state.userData().userInfo.hotelId;

      this.isSaving = true;
      const payload = { ...this.payload }
      payload.maxAdults = parseInt(payload.maxAdults);
      payload.maxChildren = parseInt(payload.maxChildren);

      try {
        const res = await axios.post(`hotels/${hotelId}/rooms`, payload);
        if (res.status === 200) {
          this.$vs.notify({
            title: "Success",
            text: res.data.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "success",
          });

          this.$router.push({ name: "vancynet-room-detail" });
        } else {
          this.$vs.notify({
            title: "Error",
            text: res.data.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        }
      } catch (err) {
        this.$catchErrorResponse(err);
      } finally {
        this.isSaving = false;
      }
    },
  },

  created() {
    this.getAllData();
  },
};
</script>

<style></style>
